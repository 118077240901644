import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Típic bolet amb capell i peu tot ell de color castany clar a marró groguenc. El capell pot fer fins a 10 cm de diàmetre que a vegades es presenta pressionat per capells veïns. El peu, fràgil, és lleugerament obès i més fi als extrems. Presenta tubs deprimits voltant el peu, lliures i separables; amb porus blancs que després tornen grocs. Les espores són grogoses en massa, el·líptiques, allargades, de 9-10 x 4-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      